import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

import blog1 from "../../assets/image/jpeg/blog1.jpg";
import blog2 from "../../assets/image/jpeg/blog2.jpg";
import blog3 from "../../assets/image/jpeg/blog3.jpg";
import blog4 from "../../assets/image/jpeg/blog4.jpg";
import blog5 from "../../assets/image/jpeg/blog5.jpg";
import blog6 from "../../assets/image/jpeg/blog6.jpg";
import stealth from "../../assets/image/jpeg/stealth.jpg";
import state1 from "../../assets/image/jpeg/state1.jpg";
import state2 from "../../assets/image/jpeg/state2.jpg";
import kycchain from "../../assets/image/jpeg/kycchain.jpg";
import state3 from "../../assets/image/jpeg/ecosystemaug.jpg";
import state4 from "../../assets/image/jpeg/ecosystemsept.jpg";
import state5 from "../../assets/image/jpeg/stateoct.jpg";
import tge from "../../assets/image/jpeg/tge.jpg";
import bnpl from "../../assets/image/jpeg/bnpl.jpg";
import state6 from "../../assets/image/jpeg/statenov.jpg";
import dca from "../../assets/image/jpeg/dca.jpg";
import whatsnext from "../../assets/image/jpeg/whatsahead.jpg";
import banxa from "../../assets/image/jpeg/banxa.jpg";
import atato from "../../assets/image/jpeg/atato.jpg";
import coinbase from "../../assets/image/jpeg/coinbase.jpg";
import realvision from "../../assets/image/jpeg/realvision.jpg";
import centre from "../../assets/image/jpeg/centre.jpg";
import ledger from "../../assets/image/jpeg/ledger.jpg";

const BlogList = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative">
      <Container>
        <Row>
          <Col lg="4" className="mb-5">
            <PostCard
              img={ledger}
              date="Mar 28, 2022"
              title="Ledger adds yield earning capability via Alkemi Earn"
              exLink="https://www.coindesk.com/business/2022/06/28/ledger-live-adds-yield-earning-capability-via-alkemi-earn/"
            >
              Ledger Live has intregated with Alkemi Earn to be able to allow
              over 1.5 million users...
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={centre}
              date="Mar 10, 2022"
              title="Alkemi joins crypto goliaths to build DeFi Standards  "
              exLink="https://cointelegraph.com/news/ftx-joins-other-crypto-goliaths-to-promote-autonomy-over-sensitive-information"
            >
              Alkemi joins 14 crypto companies partnered with Verite, including
              Coinbase, Circle, FTX..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={atato}
              date="Mar 3, 2022"
              title="Alkemi Network x Atato | Custody Partnership"
              exLink="https://medium.com/alkemi/alkemi-network-x-atato-custody-partnership-4b1649f75f60"
            >
              We’re excited to announce our partnership between Alkemi Network
              and Atato ..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={coinbase}
              date="Mar 2, 2022"
              title="Alkemi Governance Token is now on Coinbase Wallet"
              exLink="https://www.coinbase.com/how-to-buy/alkemi-network-dao-token?__cf_chl_f_tk=lyud0O2b3RybTYGlf628ihQ.uHIltPSmmWikcl75vPI-1646665517-0-gaNycGzNDD0"
            >
              The Alkemi Network DAO Token (ALK) is now officially supported by
              Coinbase Wallet
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={realvision}
              date="Jan 6, 2022"
              title="Watch Alkemi Network's Interiew on Real Vision"
              exLink="https://www.realvision.com/shows/cryptoverse/videos/alkemis-bridge-links-the-old-school-to-the-new"
            >
              Alkemi Network’s Bridge Links the Old School to the New..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={banxa}
              date="Feb 16, 2022"
              title="Alkemi Network x Banxa | Integration Partnership"
              exLink="https://medium.com/alkemi/alkemi-network-x-banxa-integration-partnership-901a9d536b32"
            >
              Alkemi Network is pleased to announce a partnership with Banxa..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={whatsnext}
              date="Jan 14, 2022"
              title="Alkemi Network: What’s Ahead?"
              exLink="https://medium.com/alkemi/alkemi-network-whats-ahead-7311428c8049"
            >
              It has been an exciting first year for the protocol, so whats
              next?..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={dca}
              date="Jan 6, 2022"
              title="Alkemi Network joins the Global DCA"
              exLink="https://medium.com/alkemi/alkemi-network-joins-the-global-dca-10cd516d6790"
            >
              Alkemi Network Joins the Global Digital Asset and Cryptocurrency
              Association..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state6}
              date="Dec 16, 2021"
              title="Ecosystem Update November 2021"
              exLink="https://medium.com/alkemi/ecosystem-update-november-2021-463d27266716"
            >
              Continuing our growth trajectory over the Thanksgiving and Holiday
              Period..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={bnpl}
              date="Nov 23, 2021"
              title="Alkemi Network & Bnpl Pay Partnership"
              exLink="https://medium.com/alkemi/alkemi-network-x-bnpl-partnership-b77f071d3ab0"
            >
              Alkemi Network is pleased to announce a partnership with the BNPL
              Pay..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state5}
              date="Nov 3, 2021"
              title="Ecosystem Update October 2021"
              exLink="https://medium.com/alkemi/ecosystem-update-september-october-2021-cff52dc67096"
            >
              ALK token is live! And have a Happy Halloween!..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state4}
              date="Sept 30, 2021"
              title="Progressive Decentralization Update"
              exLink="https://medium.com/alkemi/alkemi-network-progressive-decentralization-update-q3-2021-1d740f87af63"
            >
              Announcing that Alkemi Network’s Token Generation Event (‘TGE’)..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={tge}
              date="Sept 16, 2021"
              title="The ALK Governance Token is Born!"
              exLink="https://medium.com/alkemi/alkemi-network-tge-is-complete-unveiling-the-alk-governance-token-38d6099c73af"
            >
              Alkemi Network deployed as a Decentralized Autonomous Organization
              (DAO)..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state3}
              date="Sep 2, 2021"
              title="State of the Ecosystem  (Aug 2021)"
              exLink="https://medium.com/alkemi/alkemi-network-state-of-the-ecosystem-70e4c63869a0"
            >
              The completion of the Alkemi Network smart contract audit..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state2}
              date="May 27, 2021"
              title="State of the Ecosystem (May 2021)"
              exLink="https://medium.com/alkemi/ecosystem-update-may-2021-dca22531e054"
            >
              Welcoming growth beyond projections, borrowing is now active..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={kycchain}
              date="May 17, 2021"
              title="Alkemi Network & KYC-Chain Partnership"
              exLink="https://medium.com/alkemi/announcing-alkemi-network-kyc-chain-partnership-f87aa1f27700"
            >
              Unlocking the power of KYC-Chain’s global verification features..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={state1}
              date="May 6, 2021"
              title="State of the Ecosystem (April 2021)"
              exLink="https://medium.com/alkemi/alkemi-network-state-of-the-ecosystem-70e4c63869a0"
            >
              On-chain liquidity network with a suite of tools and products
              that..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={stealth}
              date="Apr 27, 2021"
              title="Alkemi Network Launches Out of Stealth"
              exLink="https://medium.com/alkemi/alkemi-network-launches-out-of-stealth-to-bridge-cefi-to-defi-6678858a1d8a"
            >
              Alkemi comes Out of Stealth backed by A-list of adopters..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog1}
              date="Dec 16, 2020"
              title="Dear CeFi Pragmatist: We Hear You"
              exLink="https://medium.com/alkemi/dear-cefi-pragmatist-we-hear-you-891ba9ce74f8"
            >
              Crossing over the Crypto Chasm with Alkemi Network..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog2}
              date="Nov 19, 2020"
              title="The Next Frontier of Capital Allocation"
              exLink="https://medium.com/alkemi/the-next-frontier-of-capital-allocation-7fc16aea64e9"
            >
              Alkemi is an API-based liquidity network. We are on a mission to
              bridge DeFi and CeFi..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog3}
              date="Jan 23, 2020"
              title=" Alkemi Integrates with Chainlink"
              exLink="https://medium.com/alkemi/alkemi-integrates-with-chainlink-43c442fc3b27"
            >
              Bringing additional security to its network, Alkemi has integrated
              with Chainlink..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog4}
              date="Dec 5, 2019"
              title="Shift Markets & Alkemi Liquidity Partnership"
              exLink="https://www.prnewswire.com/news-releases/shift-markets--alkemi-announce-liquidity-partnership-300970179.html"
            >
              Alkemi and Shift Markets are joining forces to address digital
              asset..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog5}
              date="Nov 14, 2019"
              title="Why Outlier Ventures Invested in Alkemi"
              exLink="https://www.outlierventures.io/outlier-ventures-invested-in-alkemi"
            >
              Investing in the liquidity super highway to power the next
              generation of..
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={blog6}
              date="May 10, 2019"
              title="Techstars Mentor: Here’s Two Epic Companies from May’s Blockchain Demo Day"
              exLink="https://medium.com/decentranet/im-a-techstars-mentor-here-s-two-epic-companies-from-may-s-blockchain-demo-day-cebd09a797d8"
            >
              Bridging the gap between traditional markets..
            </PostCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default BlogList;
