import React from 'react'
import PageTitle from '../sections/common/PageTitle'
import PageWrapper from '../components/PageWrapper'
import { Box } from '../components/Core'

import BlogList from '../sections/blog/BlogList'
import { Helmet } from 'react-helmet'

const BlogRegular = () => {
  return (
    <>
      <Helmet>
      <title>Latest News | Alkemi Network</title>
      <meta name="description" content="Alkemi Network's latest news, articles and media covering DeFi, institutions and compliance"/>
      <meta property="og:title" content="Latest News | Alkemi Network" />
      <meta property="og:description" content="Alkemi Network's latest news, articles and media covering DeFi, institutions and compliance" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content="https://alkemi.network/latest-news/" />
      <link rel="canonical" href="https://alkemi.network/latest-news/" />
        </Helmet>
      <PageWrapper footerDark>
        <PageTitle title="Latest News">
          Keep up to date with all of Alkemi Network's latest news, blogs and podcasts
          here.
        </PageTitle>
        <Box my={4}>
          <BlogList />
        </Box>
      </PageWrapper>
    </>
  )
}
export default BlogRegular
